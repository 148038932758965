import React from "react";
import classnames from "classnames";

export default function OutputList({ list }) {
  return (
    <dl className="row">
      {Object.keys(list).map((key) => {
        return (
          <React.Fragment key={key}>
            <dt className="col-5 text-end">{key}</dt>
            <dd className="col-7">
              {list[key].split(" = ").map((el, index) => {
                return (
                  <TextRight key={`${index}/${el}`} className={"pe-3"}>
                    {index === 0 ? (
                      <code>
                        {el}
                        {" = "}
                      </code>
                    ) : (
                      <code className="link-dark">
                        <strong>{el}</strong>
                      </code>
                    )}
                  </TextRight>
                );
              })}
            </dd>
          </React.Fragment>
        );
      })}
    </dl>
  );
}

// function TextCenter({children, className}){
//   return (<div className={classnames("text-center", className)}>{children}</div>)
// }

function TextRight({ children, className }) {
  return <div className={classnames("text-end", className)}>{children}</div>;
}
