import React from "react";

function ExcludeType(key) {
  return key.includes("type") ? false : true;
}
const PERCENT = "percent";
const DOLLAR = "dollar";

export default function PrettyDL({ list, update }) {
  const [toggle, setToggle] = React.useState(-1);

  return (
    <dl className="row g-0 font-monospace">
      {Object.keys(list)
        .filter(ExcludeType)
        .map((key, index) => {
          const value = list[key];
          const keytype = list[key + "_type"] || PERCENT;
          return (
            <React.Fragment key={key}>
              <dt className="col-4 text-end">
                {key}
                <span className="d-print-none"> {keytype}</span>:
              </dt>
              <dd
                onDoubleClick={() => {
                  setToggle(index);
                }}
                className="col-5 text-end border-bottom border-dark"
              >
                {index === toggle ? (
                  <input
                    className="form-control"
                    max={keytype === PERCENT ? 50 : 1000}
                    min={keytype === PERCENT ? 0 : 1}
                    step="0.01"
                    type="number"
                    autoFocus
                    value={value}
                    onChange={(e) => {
                      update({ [key]: parseFloat(e.target.value) });
                    }}
                    onBlur={() => setToggle(-1)}
                    onKeyDown={(event) => {
                      if (
                        event.key === "Tab" ||
                        event.key === "Enter" ||
                        event.key === "Escape"
                      ) {
                        setToggle(-1);
                        event.preventDefault();
                        event.stopPropagation();
                      }
                    }}
                  />
                ) : keytype === PERCENT ? (
                  `${value}%`
                ) : (
                  `$${value.toFixed(2)}`
                )}
              </dd>
              <div className="col-3">&nbsp;</div>
            </React.Fragment>
          );
        })}
    </dl>
  );
}
