import React from "react";
import dollarify from "dollarify";
import development from "development";

export default function Product({ asin, data, updateData, updateAsin }) {
  const [editing, setToggle] = React.useState(false);
  const loading = asin.length > 9 && JSON.stringify(data) === "{}";

  React.useEffect(() => {
    if (asin.length > 9) {
      fetch(`/.netlify/functions/breakdown?asin=${asin}`)
        .then((resp) => resp.json())
        .then((json) => {
          const images = json.imagesCSV.split(",").map((extension) => {
            return `https://images-na.ssl-images-amazon.com/images/I/${extension}`;
          });
          updateData(Object.assign({}, json, { images }));
          return json;
        })
        .catch((error) => {
          alert(
            "Make sure you're running the proxy.Open new Terminal Tab:\n\n$ npx netlify functions:serve\n\n" +
              error,
          );
        });
    }
  }, [asin]);

  if (data === undefined || data === null) {
    return "";
  }

  if (editing || asin.length < 9 || loading) {
    return (
      <>
        {loading ? <label>Loading...</label> : <label>&nbsp;</label>}
        <input
          className="form-control"
          type="search"
          value={asin}
          placeholder="Search"
          aria-label="Search"
          onChange={(e) => {
            updateAsin(e.target.value);
          }}
          onBlur={() => setToggle(false)}
          onKeyDown={(event) => {
            if (
              event.key === "Tab" ||
              event.key === "Enter" ||
              event.key === "Escape"
            ) {
              setToggle(false);
              event.preventDefault();
              event.stopPropagation();
            }
          }}
        />
      </>
    );
  }

  const {
    title,
    brand,
    images,
    model,
    fbaFees,
    lastUpdate,
    lastPriceChange,
    ...remainder
  } = data;
  const {
    manufacturer,
    buyBoxSellerIdHistory,
    variations,
    variationCSV,
    domainId,
    type,
    categories,
    imagesCSV,
    ...rest
  } = remainder;
  const lu = new Date((lastUpdate + 21564000) * 60000).toLocaleDateString(
    "en-US",
  );
  const lpc = new Date((lastPriceChange + 21564000) * 60000).toLocaleDateString(
    "en-US",
  );

  return (
    <div
      onDoubleClick={() => setToggle(!editing)}
      className="mx-auto"
      style={{ maxWidth: "540px" }}
    >
      <div className="row g-0 align-items-center">
        <div className="col-4">
          <img
            src={images[0]}
            className="img-fluid rounded-start"
            alt={title}
          />
        </div>
        <div className="col-8">
          <div className="card-body">
            <h5 className="card-title">{brand}</h5>
            <p className="card-text">{title}</p>
            <p className="card-text">
              <small className="text-muted">model: {model}</small>
              <br />
              <small className="text-muted">last updated: {`${lu}`}</small>
              <br />
              <small className="text-muted">
                last price change: {`${lpc}`}
              </small>
            </p>
            <p className="card-text lead">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://www.amazon.com/dp/${asin}`}
              >
                View on Amazon
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

// {development() ? (<textarea rows={50} className="form-control" readOnly defaultValue={JSON.stringify(rest, null, 4)}/>) : ''}
