import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import "./App.css";
import Navigation from "./Navigation";
import { Router } from "@reach/router";

import Grid from "./Grid";
import Breakdown from "./SKUBreakdown";
// import useLocalStorage from "useLocalStorage";
// const STATE_VERSION = "1.0.0";

const DEFAULT_STATE = {
  showDropship: false,
  asin: "B07NHGR811",
  math: {
    Retail: 99.99,
    Retail_type: "dollar",
    Cost: 49.99,
    Cost_type: "dollar",
    "Inbound Freight": 2.0,
    "Inbound Freight_type": "dollar",
    "Dropship Freight": 10.0,
    "Dropship Freight_type": "dollar",
  },
  vendor: { COOP: 10, Freight: 3.5, Damage: 2, Payment: 2 },
  seller: {
    Referral: 15,
    PickandPackFee: 5.99,
    PickandPackFee_type: "dollar",
  },
  data: {},
  offers: {},
};

export default function App({ params }) {
  // const [state, assignState] = useLocalStorage(`app${STATE_VERSION}`, DEFAULT_STATE);
  const [state, assignState] = React.useState(DEFAULT_STATE);

  function updateState(newState) {
    const ppf = newState.data?.fbaFees?.pickAndPackFee;
    if (ppf && ppf > 0) {
      newState.seller.PickandPackFee = ppf / 100;
    }
    assignState(Object.assign({}, state, newState));
  }

  function updateAsin(asin) {
    updateState({ asin });
  }

  const debugString = React.useMemo(
    () => JSON.stringify(state, null, 4),
    [state],
  );
  const rowCount = debugString.split(/\r\n|\r|\n/).length;

  return (
    <div>
      <div className="d-print-none">
        <Navigation {...{ state, updateState }} />
      </div>
      <div className="container">
        <ErrorBoundary
          FallbackComponent={({ error, resetErrorBoundary }) => {
            return (
              <div role="alert">
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <button onClick={resetErrorBoundary}>Try again</button>
              </div>
            );
          }}
          onReset={() => {
            assignState(Object.assign({}, state, { data: DEFAULT_STATE.data }));
          }}
        >
          <Router primary={false}>
            <Grid {...{ state, updateState }} path="/" />
            <Breakdown
              {...{ state, updateState, updateAsin }}
              path="/breakdown"
            />
          </Router>
        </ErrorBoundary>
      </div>
      <div className="d-print-none">
        <nav className="navbar-dark bg-primary">
          <ul className="nav nav-pills nav-fill">
            <li className="nav-item">
              <a
                className="nav-link text-light"
                href="https://sellercentral.amazon.com/gp/help/GABBX6GZPA8MSZGW?language=en_US&ref=xx_GABBX6GZPA8MSZGW_cont_201411300"
              >
                FBA Fulfillment Fees
              </a>
            </li>
            <li className="nav-item ">
              <a
                className="nav-link text-light"
                href="https://sellercentral.amazon.com/hz/fba/profitabilitycalculator/index?lang=en_US"
              >
                FBA Profit Calculator
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="d-print-none text-center">
        <details>
          <summary>Debug Data</summary>
          <textarea
            className="form-control"
            rows={rowCount}
            disabled
            readOnly
            value={debugString}
          />
        </details>
      </div>
    </div>
  );
}
