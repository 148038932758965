export default function Quote() {
  return (
    <figure>
      <blockquote className="blockquote">
        <p className="lead">
          <em>"Your Margin is My Opportunity"</em>
        </p>
      </blockquote>
      <figcaption className="blockquote-footer">
        <cite title="Jeff Bezos">Jeff Bezos</cite>
      </figcaption>
    </figure>
  );
}
