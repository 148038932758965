import React from "react";
import OutputList from "./OutputList";

export default function VendorCentralDirect({ vendor, math }) {
  const coop = vendor.COOP;
  const damage = vendor.Damage;
  const freight = vendor.Freight;
  const payment = vendor.Payment;
  const wholesale = math.Cost;
  // const retail = math.Retail;

  const allowances = coop + damage + freight + payment;
  const allowancesPercent = allowances / 100.0;
  // const deductionPercentage = (100 - allowances) / 100.0;
  // const sellerRetail = (wholesale / deductionPercentage).toFixed(2);
  const netAllowances = (allowancesPercent * wholesale).toFixed(2);
  const netProceeds = (wholesale - netAllowances).toFixed(2);
  const nets = {
    Allowances: `${coop}% + ${damage}% + ${freight}% + ${payment}% = ${allowances.toFixed(
      2,
    )}%`,
    "Net Allowances": `${allowances.toFixed(
      2,
    )}% of $${wholesale} = $${netAllowances}`,
    "Net Proceeds": `$${wholesale} - $${netAllowances} = $${netProceeds}`,
  };

  return <OutputList list={nets} />;
}
