import React from "react";
import OutputList from "./OutputList";

export default function SellerCentralFBA(props) {
  const { math, seller, data } = props;
  if (JSON.stringify(data) === "{}") {
    return "";
  }
  const { fbaFees } = data;
  const retail = math.Retail;

  const allowances = ((retail * 15) / 100.0).toFixed(2);
  const outbound = fbaFees.pickAndPackFee / 100.0;
  const inbound = math["Inbound Freight"];
  const freight = inbound + outbound;
  const netProceeds = retail - allowances - inbound - freight;

  const nets = {
    Allowances: `${seller.Referral}% off Retail = ($${retail} * 0.15) = ${allowances}`,
    Freight: `Inbound + Outbound = Inbound ($${inbound.toFixed(
      2,
    )}) + Outbound $${outbound.toFixed(2)} = $${freight.toFixed(2)}`,
    "Net Proceeds": `$${retail} - $${allowances} - $${freight.toFixed(
      2,
    )} = $${netProceeds.toFixed(2)}`,
  };

  return <OutputList list={nets} />;
}
