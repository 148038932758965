import React from "react";
import Product from "Product";
import useTitle from "useTitle";
import Row from "Row";
import GridHeader from "GridHeader";
import CellHeader from "CellHeader";
import PrettyDL from "PrettyDL";
import VendorCentralDirect from "./VendorCentralDirect";
import SellerCentralFBA from "./SellerCentralFBA";
import VendorCentralDropship from "./VendorCentralDropship";
import SellerCentralDropship from "./SellerCentralDropship";

export default function Breakdown({ state, updateState, updateAsin }) {
  useTitle("ASIN/SKU Breakdown VendorCentral VS. SellerCentral Migration");
  const { asin, data, vendor, math } = state;

  return (
    <>
      <Row>
        <div className="col-6">
          <h1 className="text-right text-center">SKU Breakdown</h1>
        </div>
        <div className="col-4"></div>
      </Row>
      <Row>
        <div className="border-bottom border-danger" />
      </Row>
      <Row className={"align-items-center"}>
        <div className="col-6 border-bottom border-end border-danger">
          <PrettyDL
            list={vendor}
            type="percent"
            update={(newState) => {
              updateState({
                vendor: Object.assign({}, vendor, newState),
              });
            }}
          />
          <PrettyDL
            list={math}
            type="dollar"
            update={(newState) => {
              updateState({ math: Object.assign({}, math, newState) });
            }}
          />
        </div>
        <div className="col-6">
          <Product
            updateAsin={updateAsin}
            updateData={(newData) => {
              updateState(Object.assign({}, state, { data: newData }));
            }}
            data={data}
            asin={asin}
          />
        </div>
      </Row>
      <Row>
        <div className="col-6 border-end border-top border-bottom border-danger">
          <GridHeader>VendorCentral</GridHeader>
        </div>
        <div className="col-6 border-start border-top border-bottom border-warning">
          <GridHeader>SellerCentral</GridHeader>
        </div>
      </Row>
      <Row>
        <div className="col-6 border-end border-bottom border-danger">
          <CellHeader>1. Replenishment (Ship to FC)</CellHeader>
          <VendorCentralDirect {...state} />
        </div>
        <div className="col-6 border-start border-bottom border-warning">
          <CellHeader>3. Direct (FBA / Consignment)</CellHeader>
          <SellerCentralFBA {...state} />
        </div>
      </Row>
      <Row>
        <div className="col-6 border-end border-top border-danger">
          <CellHeader>2. Dropship (DirectFulfillment)</CellHeader>
          <VendorCentralDropship {...state} />
        </div>
        <div className="col-6 border-start border-top border-warning">
          <CellHeader>4. Dropship (FBM / Fulfilled By Merchant)</CellHeader>
          <SellerCentralDropship {...state} />
        </div>
      </Row>
    </>
  );
}
