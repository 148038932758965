import React from "react";
import useTitle from "useTitle";
import Quote from "Quote";
import Row from "Row";
import PrettyDL from "PrettyDL";
import GridHeader from "GridHeader";
import CellHeader from "CellHeader";

export default function Grid({ state, updateState }) {
  useTitle("VendorCentral VS. SellerCentral Migration");
  const { Freight, ...vendorDF } = state.vendor;
  const { PickandPackFee, ...sellerDF } = state.seller;
  const [vendorFacts, updateVendorFacts] = React.useState([
    "Amazon sends POs / Vendor accepts or rejects.",
    "Pricing cannot be changed on existing SKUs.",
    "NEW SKUs have price/cost flexibility.",
  ]);
  const [dfFacts, updateDfFacts] = React.useState([
    "Vendor ships inventory to Amazon warehouse",
    "Pricing can be changed anytime.",
    "Amazon handles Fulfillment pick/pack/ship.",
  ]);
  const [fbaFacts, updateFBAFacts] = React.useState([
    "Vendor uploads inventory via FEED/EDI.",
    "Pricing cannot be changed on existing SKUs.",
    "NEW SKUs have price/cost flexibility.",
    "Freight is paid 100% Collect / No Allowance.",
  ]);
  const [fbmFacts, updateFBMFacts] = React.useState([
    "Vendor uploads inventory via FEED/EDI",
    "Pricing can be changed anytime.",
    "Vendor responsible for freight.",
    "Vendor responsible for damage.",
    "Vendor responsible for customer-service.",
  ]);

  function updateVendor(newState) {
    updateState({ vendor: Object.assign({}, state.vendor, newState) });
  }

  function updateSeller(newState) {
    updateState({ seller: Object.assign({}, state.seller, newState) });
  }

  return (
    <>
      <Row>
        <div className="col-6 border-end border-bottom border-danger">
          <GridHeader>VendorCentral</GridHeader>
        </div>
        <div className="col-6 border-start border-bottom border-warning">
          <GridHeader>SellerCentral</GridHeader>
        </div>
      </Row>
      <Row>
        <div className="col-6 border-end border-bottom border-danger">
          <CellHeader>1. Replenishment (Ship to FC)</CellHeader>
          <ProsCons facts={vendorFacts} update={updateVendorFacts} />
          <PrettyDL list={state.vendor} update={updateVendor} />
        </div>
        <div className="col-6 border-start border-bottom border-warning">
          <CellHeader>3. Direct (FBA / Consignment)</CellHeader>
          <ProsCons facts={fbaFacts} update={updateFBAFacts} />
          <PrettyDL list={state.seller} update={updateSeller} />
        </div>
      </Row>
      <Row>
        <div className="col-12 text-center p-4">
          <Quote />
        </div>
      </Row>
      {state.showDropship ? (
        <Row>
          <div className="col-6 border-end border-top border-danger">
            <CellHeader>2. Dropship (DirectFulfillment)</CellHeader>
            <ProsCons facts={dfFacts} update={updateDfFacts} />
            <PrettyDL list={vendorDF} update={updateVendor} />
          </div>
          <div className="col-6 border-start border-top border-warning">
            <CellHeader>4. Dropship (FBM / Fulfilled By Merchant)</CellHeader>
            <ProsCons facts={fbmFacts} update={updateFBMFacts} />
            <PrettyDL list={sellerDF} update={updateSeller} />
          </div>
        </Row>
      ) : null}
    </>
  );
}

function ProsCons({ facts, update }) {
  const [editing, toggle] = React.useState(false);

  function blur() {
    toggle(false);
  }

  return (
    <>
      <ul>
        {facts.map((el, index) => {
          return (
            <ListInput
              key={index}
              el={el}
              editing={editing === index}
              toggle={(e) => {
                toggle(index);
              }}
              onBlur={blur}
              onRemoveClick={(e) => {
                e.preventDefault();
                update(facts.filter((_, i) => i !== index));
              }}
              onChange={(event) => {
                update(
                  Object.assign([], facts, { [index]: event.target.value }),
                );
              }}
            />
          );
        })}
      </ul>
      <button
        className="d-print-none"
        onClick={() => {
          update(facts.concat([""]));
          setTimeout(() => {
            toggle(facts.length - 1);
          }, 10);
        }}
      >
        +
      </button>
    </>
  );
}

function ListInput({ el, onChange, onRemoveClick, editing, toggle, onBlur }) {
  return (
    <li>
      {editing ? (
        <input
          onBlur={onBlur}
          type="text"
          value={el}
          onChange={onChange}
          className="form-control"
        />
      ) : (
        <div onDoubleClick={toggle} className="d-flex justify-content-between">
          <small>{el}</small>
          {el.length < 3 && (
            <button className="badge bg-primary" onClick={onRemoveClick}>
              remove
            </button>
          )}
        </div>
      )}
    </li>
  );
}
