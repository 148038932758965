import { Link } from "@reach/router";
import development from "./node_modules/development";

export default Navigation;

function Navigation({ state, updateState }) {
  return (
    <>
      {development() ? (
        <div className="text-center">
          <code>{JSON.stringify(process.env, null, 4)}</code>
        </div>
      ) : (
        ""
      )}
      <nav className="navbar navbar-light bg-light">
        <div className="container">
          <Link className="navbar-brand" to="/">
            Vendor VS Seller
          </Link>
          <ul className="nav nav-pills nav-fill me-auto">
            <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="breakdown">
                ASIN Breakdown
              </Link>
            </li>
            <li className="nav-item">
              <label className="nav-link" htmlFor="showDropship">
                <input
                  type="checkbox"
                  name="showDropship"
                  id="showDropship"
                  checked={state.showDropship}
                  onClick={(e) => {
                    updateState({ showDropship: e.target.checked });
                  }}
                />
                Show DropShip
              </label>
            </li>
          </ul>
          <a
            className="navbar-brand"
            target="_blank"
            rel="noreferrer"
            href="https://vcvs.fbajobs.com"
          >
            <img
              alt="website build status"
              src="https://api.netlify.com/api/v1/badges/b92151a2-be7e-4fa4-a255-009af2df851a/deploy-status"
            />
          </a>
        </div>
      </nav>
    </>
  );
}
