import React from "react";
import OutputList from "./OutputList";

export default function SellerCentralDropship(props) {
  const { math, seller } = props;
  const retail = math.Retail;

  const allowances = ((retail * 15) / 100.0).toFixed(2);
  const freight = math["Dropship Freight"].toFixed(2);
  const freightmax = (freight * 1.25).toFixed(2);
  const freightmin = (freight * 0.75).toFixed(2);
  const netProceeds = retail - allowances - math["Dropship Freight"];

  const nets = {
    Allowances: `${seller.Referral}% off Retail = ($${retail} * 0.15) = ${allowances}`,
    "Outbound Freight": `EST $${freightmin} - $${freightmax} = ${freight}`,
    "Net Proceeds": `$${retail} - $${allowances} - $${
      math["Dropship Freight"]
    } = $${netProceeds.toFixed(2)}`,
  };

  return <OutputList list={nets} />;
}
